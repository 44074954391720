import {graphql, useStaticQuery} from 'gatsby';

export const useCookies = () => {
  const {allCookiesJson} = useStaticQuery(graphql`
    query CookiePolicy {
      allCookiesJson {
        edges {
          node {
            title
            description
            category
            table {
              labels
              rows
            }
          }
        }
      }
    }
  `);

  return allCookiesJson.edges;
};

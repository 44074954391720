import React from 'react';

// Layout
import Layout from '../containers/layout/Layout';

// Components
import TextWidget from '../components/common/text-widget/TextWidget';

// Data
import {useCookies} from '../graphql/cookies/cookies';

const Cookies = () => {
  const cookieData = useCookies();

  return (
    <Layout>
      <h2 style={{marginTop: '16px', marginBottom: '19px'}}>
        ENSEMBLE SYSTEMS GROUP COOKIE POLICY
      </h2>
      <TextWidget data={cookieData} className="text-widget--cookies" />
    </Layout>
  );
};

export default Cookies;
